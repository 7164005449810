<template>
  <div class="cuotiben_Warp">
    <COMMONTOPSEARCH ref="commonTopSearchRef"
                     @initData="getPaperList" />
    <div class="topic_warp">
      <div class="paper"
           v-for="(item,index) in paperList"
           :key="index">
        <div class="content_wrap">
          <div class="top">
            <div class="title">
              {{item.knowledge_desc}}
            </div>
            <div style="display:flex;gap:10rem;">
              <div class="pl">
                易错指数:{{item.error_prone}}
              </div>
              <div class="pl">
                <span>
                  频率:</span>
                <img src="@/static/zhishidian/xx.jpg"
                     style="width:20rem;height:20rem"
                     v-for="(item,idx) in item.knowledge_point_star"
                     :key="idx" />
              </div>
            </div>
          </div>
          <div class="btn">
            <div @click="showOther(item)">
              二级条目
            </div>
            <div @click="toDopaper(item)">
              进入训练
            </div>
          </div>
        </div>

      </div>
    </div>
    <OtherForm ref="otherFormRef" />
  </div>
</template>

<script setup>
import COMMONTOPSEARCH from '@/views/components/COMMON_TOP_SEARCH/index.vue'
import OtherForm from './components/otherKnowledge.vue'
import { ref } from '@vue/reactivity'
import { getKnowledgeList } from '@/api/knowledge.js'
import { message } from 'ant-design-vue'
import { useRouter } from 'vue-router'

const router = useRouter()
let paperList = ref([])
const otherFormRef = ref(null)

const getPaperList = async (id, keywords) => {
  let params = {
    subject_id: id,
    keywords: keywords
  }
  const { data } = await getKnowledgeList(params)
  paperList.value = data.list.map(item => {
    item.knowledge_point_frequency = item.knowledge_point_frequency.toFixed(2)
    return item
  })
}

const showOther = (item) => {
  if (!item.child || item.child.length == 0) {
    message.warning('该条目涉及题目暂时较少，请返回上一级训练')
    return
  }
  item.child.map(item => {
    item.active = false
    return item
  })
  otherFormRef.value.subjectId = item.subject_id
  otherFormRef.value.list = item.child
  otherFormRef.value.dialogVisible = true
}

const toDopaper = (item) => {
  let form = {
    // subject_id: subjectId.value,
    knowledge_id: item.knowledge_id
  }
  window.sessionStorage.removeItem('testpaperCardIndex')
  window.sessionStorage.removeItem('paper')
  router.push('/doPaper?isKnowledge=1&form=' + JSON.stringify(form))
}

</script>

<style lang="scss" scoped>
.cuotiben_Warp {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100vh;
  background-image: url("../../static/allBackg/考点训练.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .topic_warp {
    width: calc(100vw - 75rem);
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    overflow-y: auto;
    margin: 0 auto;
    margin-top: 10rem;
    height: calc(100vh - 210rem);
    .paper {
      width: 430rem;
      height: 220rem;
      background: #b29bfa;
      border-radius: 20rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 25rem;
      margin-top: 30rem;
      .content_wrap {
        width: 410rem;
        height: 200rem;
        box-shadow: 0px 2px 8px 0px rgba(98, 47, 215, 1);
        overflow: hidden;
        border-radius: 20rem;
        .top {
          height: 146rem;
          background: white;
          padding: 16rem;
          .title {
            font-size: 22rem;
            font-weight: bold;
            color: #000000;
            overflow: hidden;
            height: 68rem;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .pl {
            margin-top: 12rem;
            font-size: 22rem;
            font-weight: 500;
            color: #999999;
            display: flex;
            align-items: center;
          }
        }
        .btn {
          display: flex;
          height: 54rem;
          justify-content: center;
          align-items: center;
          box-shadow: 0px 2px 8px 0px rgba(98, 47, 215, 1);
          background: #a68cf3;
          div {
            flex: 1;
            height: 30rem;
            text-align: center;
            font-size: 22rem;
            font-weight: bold;
            color: #ffffff;
            cursor: pointer;
            &:nth-child(1) {
              border-right: 2rem solid rgba(255, 255, 255, 0.5);
            }
          }
        }
      }
    }
  }
}
.active {
  font-weight: bold;
  color: white !important;
}
</style>
